import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ProductDetails } from "../../modals/Product";
import { Question } from "../../modals/Question";
import { GetQuestionListService } from "../../services/services";
import AppContext from "../../context/AppContext";
import { history } from "../../utils/appConfig";
import { MODULES } from "../../utils/routesNames";
import AskQuestionForm from "./AskQuestionForm";
import Nav from "react-bootstrap/esm/Nav";
import Loader from "../Loader";
import { generalMessages } from "../../utils/constants";
import { MODAL_CLOSE } from "../../assets/images/images";

const AskQuestion: React.FC<ProductDetails> = ({ id, name }) => {
  const { auth, tokenExpired } = useContext(AppContext);
  const [modalShow, setModalShow] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedQuestion, setselectedQuestion] = useState<Question | null>(
    null
  );

  const getQuestions = () => {
    GetQuestionListService()
      .then((result) => {
        if (result.data.flag) {
          setQuestions(result.data.data);
          setStatus(null);
        } else {
          setStatus(generalMessages.ERROR_MSG);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  const handleModalClose = () => {
    setModalShow(false);
    setStatus(null);
    setQuestions([]);
    setselectedQuestion(null);
  };

  const handleModalOpen = () => {
    if (auth) {
      setModalShow(true);
      getQuestions();
    } else {
      history.push(MODULES.login.url);
    }
  };

  const modalContent = selectedQuestion ? (
    <AskQuestionForm
      question={selectedQuestion}
      productId={id}
      closeModal={handleModalClose}
    />
  ) : (
    <div className="question-wrapper">
      {status ? (
        status
      ) : questions.length !== 0 ? (
        questions.map((question) => (
          <div
            onClick={() => setselectedQuestion(question)}
            key={question.id}
            className="single-question"
          >
            <label htmlFor="Que1">{question.question}</label>
          </div>
        ))
      ) : (
        <Loader />
      )}
    </div>
  );

  return (
    <>
      <Nav.Link
        style={{ paddingLeft: "0" }}
        className="que-modal-link"
        onClick={() => handleModalOpen()}
        title="ask a question about this product"
      >
        ask a question about this product
      </Nav.Link>
      <Modal
        className="primary-modal"
        size="sm"
        show={modalShow}
        onHide={handleModalClose}
        centered
      >
        <Modal.Header>
          <Modal.Title>Ask a question about this product</Modal.Title>
          <p className="product-name">{name}</p>
          <Button className="btn btn-icon" onClick={handleModalClose}>
            <img src={MODAL_CLOSE} alt="close" />
          </Button>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            className="btn btn-link-danger"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AskQuestion;
