import React from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
export default function PrivacyPolicy() {
  window.scroll({ top: 0, left: 0, behavior: "smooth" });
  return (
    <div>
      <div className="wrapper">
        <div className="sticky-header">
          <Navbar />
        </div>
        {/* Start About Block */}
        <section className="content-block about-block no-bg pt-sm-5">
          <div className="container-xl text-justify">
            <h2 className="content-heading text-center pb-sm-5 pb-4">Privacy Policy</h2>
            <h5 className="font-weight-bold">SCOPE</h5>
            <p className="para">
              This website is owned and operated by ARZ Food Service Australia
              Limited and its associated businesses (ARZ Food Service, we, us
              and similar expressions). ARZ Food Service is subject to the
              Australian Privacy Principles (APPs) in the Privacy Act 1988
              (Cth). Through its compliance with the APPs, ARZ Food Service is
              committed to dealing responsibly with information it holds about
              people.
            </p>
            <p className="para">
              This Privacy Statement explains the type of personal information
              that is collected by ARZ Food Service on this website, and how we
              use and protect that information in accordance with the APPs. It
              also sets out the matters which ARZ Food Service is required to
              disclose under the APPs. The principles set out in this Privacy
              Statement apply only to personal information which you provide to
              us via this website.
            </p>
            <br />

            <h5 className="font-weight-bold text-left">
              COLLECTION OF PERSONAL INFORMATION
            </h5>
            <p className="para">
              Unless we have advised you otherwise, we do not collect personal
              information from you unless you knowingly provide it to us. As a
              general rule, we will only collect personal information where it
              is necessary for a function or activity which is performed via
              this website. For example, we may collect personal information
              about you when you provide information to us for the purposes of
              conducting business with us or to receive information via this
              website. We may also collect personal information about you for
              the purposes of providing you with promotional material or
              handling complaints.
            </p>
            <p className="para">
              The type of personal information we collect about you generally
              includes your name, email address, postal address, telephone
              number and your business details. We will only collect sensitive
              information about you where:
            </p>
            <ul className="unordered-list">
              <li>You have consented to us doing so or</li>
              <li>We are required by law to do so</li>
            </ul>
            <p className="para">
              We may supplement the information we receive from you with
              personal information about you which we receive from third
              parties. We will only collect information from third parties for
              assessing your credit application, marketing purposes or to
              provide products and services which better meet your needs.
            </p>
            <br />

            <h5 className="font-weight-bold">USE AND DISCLOSURE</h5>
            <p className="para">
              ARZ Food Service collects personal information about you via this
              website for purposes which include the following:
            </p>
            <ul className="unordered-list">
              <li>Your registration as a user of ARZ Food Service Online.</li>
              <li>The delivery of rewards under our Loyalty Program.</li>
              <li>
                To receive questions from you about our products and services.
              </li>
              <li>
                To inform you about ARZ Food Service, its products, promotions,
                special offers and other information we think you might be
                interested in.
              </li>
              <li>To confirm your identity.</li>
            </ul>
            <p className="para">
              ARZ Food Service will use and disclose personal information about
              you for the primary purpose for which it was collected. We may
              also use and disclose personal information for purposes related or
              ancillary to the primary purpose for which it was collected (such
              as invoicing, participation in our Loyalty program or product
              promotions).
            </p>
            <p className="para">
              We do not disclose personal information we collect to third
              parties for the purposes of those third parties conducting direct
              marketing.
            </p>
            <p className="para">
              We may engage third parties to process and assess information we
              collect via this website, including personal information about
              you. We will only use your personal information in this way for
              the purpose of assessing your credit worthiness and improving our
              products and services.
            </p>
            <br />

            <h5 className="font-weight-bold">ACCESS TO INFORMATION</h5>
            <p className="para">
              On request by you, we will provide you with access to the personal
              information we hold about you (unless an exception under the
              Privacy Act applies, such as where doing so would be unlawful). We
              will process your request for access to your personal information
              in a reasonable time.
            </p>
            <p className="para">
              If we refuse to provide you with access to the information, we
              will explain the reasons for this and inform you of any exceptions
              under the Privacy Act which we have relied upon in doing so.
            </p>
            <br />

            <h5 className="font-weight-bold">ACCESS OF INFORMATION</h5>
            <p className="para">
              ARZ Food Service takes reasonable steps to ensure that the
              information it holds about you is accurate and complete whenever
              it collects or uses that information. Please contact us if you
              believe or know that personal information we hold about you is
              inaccurate or incomplete, so that we can take reasonable steps to
              correct this information.
            </p>
            <br />

            <h5 className="font-weight-bold">SECURITY</h5>
            <p className="para">
              We take all reasonable steps to protect the personal information
              we hold about visitors to this website from loss and unauthorized
              access, modification or disclosure. Your personal information may
              be in hard copy or electronic format. We maintain physical
              security over hard copy data and attempt to maintain network and
              computer security to protect information kept in electronic
              format. However, we remind you that no data transmission over the
              Internet is unconditionally secure, and that any information
              disclosed online may potentially be collected and used by parties
              other than the intended recipient.
            </p>
            <br />

            <h5 className="font-weight-bold text-left">CHANGES TO PRIVACY STATEMENT</h5>
            <p className="para">
              ARZ Food Service reserves the right to modify or remove this
              Privacy Statement at its discretion.
            </p>
            <br />

            <h5 className="font-weight-bold">CONTACT US</h5>
            <p className="para">If you wish to:</p>
            <ul className="unordered-list">
              <li>
                Access or update the personal information we hold about you.
              </li>
              <li>Elect that we do not contact you in the future.</li>
              <li>Make a complaint about a breach of your privacy.</li>
            </ul>
            <p className="para">
              or if you have any comments or questions about this Privacy
              Statement, please contact us in one of the following ways:
            </p>
            <h5 className="font-weight-bold">By Telephone: </h5>
            <p className="para">Australia Freecall 03 9355 8099</p>
            <p className="para">We will respond to your query or complaint as soon as possible.</p>
            <br />
          </div>
        </section>
        {/* End About Block */}
      </div>
      <Footer />
    </div>
  );
}
