import React, { useContext, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import * as Yup from "yup";

import { ProductDetails } from "../../modals/Product";
import AppContext from "../../context/AppContext";
import { AddToCart } from "../../modals/Cart";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { MODULES } from "../../utils/routesNames";
import { Link } from "react-router-dom";
import {
  AddToCartService,
  ViewCartService,
  UpdateCartService,
  AddSavedProductService,
} from "../../services/services";
import {
  addToCartMessages as MSG,
  generalMessages,
} from "../../utils/constants";
import { INCREMENT, DECREMENT } from "../../assets/images/svgImage";
import { toast } from "react-toastify";

const addToCartSchema = Yup.object({
  Quantity: Yup.number()
    .min(MSG.MIN_QTY, MSG.QTY_REQUIRED)
    .max(MSG.MAX_QTY, MSG.QTY_EXCEEDED)
    .positive(MSG.INVALID_QTY)
    .integer(MSG.INVALID_QTY)
    .required(MSG.QTY_REQUIRED),
});

const AddToCartForm: React.FC<ProductDetails> = ({
  id,
  is_saved,
  out_of_stock,
}) => {
  const { auth, cart, setCart, tokenExpired } = useContext(AppContext);

  const [quantityState, setQuantityState] = useState<AddToCart>({
    UserID: auth?.id,
    ProductID: id,
    Quantity: 1,
  });

  const [cartId, setCartId] = useState<number | null>(null);

  useEffect(() => {
    const cartItem = cart.find((product) => product.product_id === id);
    if (auth && cartItem?.quantity) {
      setCartId(cartItem.id);
      setQuantityState({ ...quantityState, Quantity: cartItem.quantity });
    }
  }, [cart, auth]); // eslint-disable-line

  const [loading, setLoading] = useState(false);

  const addProductToCart = (values: AddToCart, bag: any) => {
    let result: Promise<any>;
    if (cartId) {
      result = UpdateCartService(cartId, values.ProductID, values.Quantity);
    } else {
      result = AddToCartService(values);
    }
    result
      .then((result) => {
        if (result.data.flag) {
          toast.success(result.data.message);
        } else {
          toast.error(generalMessages.ERROR_MSG);
        }
        bag.setSubmitting(false);
        return ViewCartService();
      })
      .then((result) => {
        if (result.data.flag) {
          setCart(result.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  const addToSavedList = (values: AddToCart) => {
    setLoading(true);
    AddSavedProductService(values.ProductID, "commonly")
      .then((result) => {
        if (result.data.flag) {
          setLoading(false);
          toast.success(result.data.message);
        } else {
          toast.error(result.data.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          tokenExpired();
        }
      });
  };

  return (
    <div className="action-bar">
      {auth && (
        <Formik
          enableReinitialize
          validateOnChange
          validateOnBlur
          validationSchema={addToCartSchema}
          initialValues={quantityState}
          onSubmit={(values, bag) => addProductToCart(values, bag)}
        >
          {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
            <FormikForm
              className="action-bar"
              noValidate
              onSubmit={handleSubmit}
            >
              <div className="number-input">
                <Form.Group className="form-group">
                  <Field
                    type="number"
                    min="1"
                    max="99"
                    name="Quantity"
                    className="form-control"
                    placeholder="Qty"
                    disabled={out_of_stock}
                  />
                  <span className="invalid-control">
                    <ErrorMessage name="Quantity" />
                  </span>
                  <span className="action">
                    <Button
                      onClick={() => {
                        if (values.Quantity + 1 <= MSG.MAX_QTY)
                          setFieldValue("Quantity", values.Quantity + 1, true);
                      }}
                      className="btn btn-icon"
                    >
                      <img src={INCREMENT} alt="^" />
                    </Button>
                    <Button
                      onClick={() => {
                        if (values.Quantity - 1 >= MSG.MIN_QTY)
                          setFieldValue("Quantity", values.Quantity - 1, true);
                      }}
                      className="btn btn-icon"
                    >
                      <img src={DECREMENT} alt="v" />
                    </Button>
                  </span>
                </Form.Group>
              </div>
              <div>
                {!out_of_stock && (
                  <Button
                    type="submit"
                    name="addToCart"
                    variant="primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner animation="border" size="sm" variant="light" />
                    ) : cartId ? (
                      "Update Cart"
                    ) : (
                      "Add to cart"
                    )}
                  </Button>
                )}
                {is_saved === 0 && (
                  <Button
                    disabled={loading}
                    onClick={() => addToSavedList(values)}
                    variant="accent"
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" variant="light" />
                    ) : (
                      "add to list"
                    )}
                  </Button>
                )}
              </div>
            </FormikForm>
          )}
        </Formik>
      )}

      {/* not logged in user */}
      {!auth && (
        <Button as={Link} to={MODULES.login.url} variant="primary">
          Add to Cart
        </Button>
      )}
      {!auth && (
        <Button as={Link} to={MODULES.login.url} variant="accent">
          Add to List
        </Button>
      )}
    </div>
  );
};

export default AddToCartForm;
