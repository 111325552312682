import React from "react";
import { LOCATION, CONTACT, EMAIL } from "../../assets/images/svgImage";
import { GOOGLE_MAP } from "../../utils/constants";
import ScrollAnimation from "react-animate-on-scroll";

export default function contact() {
  return (
    <div>
      {/* Start Contact Block */}
      <section className="content-block contact-block">
        <div className="container-xl">
          <div className="row no-gutters">
            <div className="col-lg-5 order-lg-1 order-2">
              <div className="contact-details-block">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <h2 className="content-heading">Contact ARZ Food</h2>
                </ScrollAnimation>
                <ul className="contact-listing">
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOnce={true}
                    delay={100}
                  >
                    <li>
                      <img src={LOCATION} alt="location" />
                      12 Attercliffe Avenue, Pascoe Vale Melbourne, Victoria
                      3044
                    </li>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOnce={true}
                    delay={250}
                  >
                    <li>
                      <img src={CONTACT} alt="contact" />
                      <a href="tel:03 9355 8099">03 9355 8099</a>
                    </li>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOnce={true}
                    delay={400}
                  >
                    <li>
                      <img src={CONTACT} alt="contact" />
                      <a href="tel:0432 145 925">0432 145 925</a> (Sales)
                    </li>
                  </ScrollAnimation>

                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOnce={true}
                    delay={550}
                  >
                    <li>
                      <img src={EMAIL} alt="landline" />
                      <a href="mailto:enquiry@arzfoodservice.com.au">enquiry@arzfoodservice.com.au</a>
                    </li>
                  </ScrollAnimation>
                </ul>
              </div>
            </div>
            <div className="col-lg-7 order-lg-2 order-1">
              <iframe
                className="location-map"
                title="Location of Shop"
                src={GOOGLE_MAP}
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {/* End Contact Block */}
    </div>
  );
}
