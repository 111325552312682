import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { getPathTo, findNestedObj } from "../../utils/common";

import CategoryItem from "./categoryItem";
import { Category } from "../../modals/Category";
import { CategoryService } from "../../services/services";
import { history } from "../../utils/appConfig";
import { MODULES } from "../../utils/routesNames";
import Loader from "../Loader/index";
import { generalMessages as MSG } from "../../utils/constants";

const CategoryGrid = ({ location: { state } }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [root, setRoot] = useState<Category[]>([]);
  const [status, setStatus] = useState<string | null>(null);
  const [limit, setLimit] = useState(100);
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  useEffect(() => {
    if (state) {
      initializeRoot();
      setCategories(state.item.sub_categories);
      setBreadCrumbs(state.path);
      // state = null;
      history.replace({...history.location, state: null })
    } else {
      getCategories();
    }
  }, []); // eslint-disable-line

  const initializeRoot = async () => {
    const result = await CategoryService();
    if (result) {
      setRoot(result.data.data);
    }
  };

  const getCategories = async () => {
    const result = await CategoryService();
    if (result.data.flag) {
      setStatus(null);
      if (result.data.data.length !== 0) {
        setCategories(result.data.data);
        setRoot(result.data.data);
      } else {
        setStatus(MSG.EMPTY_CATEGORIES);
      }
    } else {
      setStatus(MSG.ERROR_MSG);
    }
  };

  const loadsubcategories = (id: number) => {
    const item = findNestedObj(root, "id", id);
    const path = getPathTo(item ? item.name : "", root);
    const subCategories = categories.find((category) => category.id === id)
      ?.sub_categories;
    if (subCategories) {
      setCategories(subCategories);
      setBreadCrumbs(path);
    } else {
      history.push(`${MODULES.productsList.url}/${id}`);
    }
  };

  const jumpToCategory = (name: string) => {
    const item = findNestedObj(root, "name", name);
    const path = getPathTo(item ? item.name : "", root);
    if (item && item.sub_categories) {
      setCategories(item.sub_categories);
      setBreadCrumbs(path);
    } else {
      setCategories(root);
      setBreadCrumbs(path);
    }
  };

  return (
    <div className="container-xl px-xl-0">
      <Breadcrumb className="primary-breadcrumb">
        {breadCrumbs &&
          breadCrumbs.map((item, index) => {
            return index === breadCrumbs.length - 1 ? (
              <Breadcrumb.Item
                onClick={() => jumpToCategory(item)}
                active
                key={index}
              >
                <span className="text-truncate">{item}</span>
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item key={index} onClick={() => jumpToCategory(item)}>
                {item}
              </Breadcrumb.Item>
            );
          })}
      </Breadcrumb>
      <div className="row">
        {status ? (
          status
        ) : categories.length !== 0 ? (
          categories
            .slice(0, limit)
            .map((category) => (
              <CategoryItem
                key={category.id}
                {...category}
                loadSubCategories={() => loadsubcategories(category.id)}
              />
            ))
        ) : (
          <Loader />
        )}
      </div>
      {!status && limit < categories.length && (
        <div className="text-center">
          <Button
            variant="primary"
            onClick={() => setLimit((prevLimit) => prevLimit + limit)}
          >
            View More
          </Button>
        </div>
      )}
    </div>
  );
};

export default CategoryGrid;
