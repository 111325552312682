import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/esm/Modal";
import Spinner from "react-bootstrap/esm/Spinner";
import * as Yup from "yup";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { GeneralInquiry } from "../../modals/GeneralInquiry";
import { GeneralInqiryService } from "../../services/services";
import { MODAL_CLOSE, } from "../../assets/images/images";
import { SUCCESSFULL_ORDER } from "../../assets/images/svgImage";
import { contactUsMessages as MSG } from "../../utils/constants";

const contactUsSchema = Yup.object({
  name: Yup.string().required(MSG.NAME_REQUIRED),
  email: Yup.string().email(MSG.INVALID_EMAIL).required(MSG.EMAIL_REQUIRED),
  description: Yup.string()
    .max(MSG.MESSAGE_MAX_LENGTH, MSG.LIMIT_EXCEEDED)
    .required(MSG.MESSAGE_REQUIRED),
});

const ContactForm = () => {
  const [contactUsState] = useState<GeneralInquiry>({
    name: "",
    email: "",
    description: "",
  });

  const [show, setShow] = useState(false);

  const sendContactUsRequest = async (
    values: GeneralInquiry,
    { resetForm }
  ) => {
    const result = await GeneralInqiryService(values);
    if (result.data.flag) {
      setShow(true);
      resetForm();
    }
  };

  return (
    <div className="form-block">
      <h3 className="page-title">send a message</h3>
      <Formik
        enableReinitialize
        validateOnChange
        validateOnBlur
        validationSchema={contactUsSchema}
        initialValues={contactUsState}
        onSubmit={sendContactUsRequest}
      >
        {({ handleSubmit, isSubmitting }) => (
          <FormikForm noValidate onSubmit={handleSubmit}>
            <Form.Group className="form-group">
              <Form.Label>Name</Form.Label>
              <Field name="name" type="text" className="form-control" />
              <span className="errorMessage">
                <ErrorMessage name="name" />
              </span>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Email</Form.Label>
              <Field name="email" type="email" className="form-control" />
              <span className="errorMessage">
                <ErrorMessage name="email" />
              </span>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>Message</Form.Label>
              <Field
                name="description"
                as="textarea"
                className="form-control"
              />
              <span className="errorMessage">
                <ErrorMessage name="description" />
              </span>
            </Form.Group>
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner animation="border" variant="light" />
              ) : (
                "send"
              )}
            </Button>
          </FormikForm>
        )}
      </Formik>

      <Modal
        className="alert-modal"
        size="sm"
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Body>
          <Button onClick={() => setShow(false)} className="btn btn-icon">
            <img src={MODAL_CLOSE} alt="close" />
          </Button>
          <img className="event-img" src={SUCCESSFULL_ORDER} alt="success" />
          <h4 className="event-title">{MSG.SUCCESS}</h4>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ContactForm;
