import React from "react";
import ProductsNavbar from "../../components/Products/productsNavbar";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";

export default function Products() {
  window.scroll({ top: 0, left: 0, behavior: "smooth" });
  return (
    <div>
      <div className="wrapper">
        <div className="sticky-header">
          <Navbar />
        </div>
        <ProductsNavbar />
      </div>
      <Footer />
    </div>
  );
}
