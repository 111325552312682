import React from "react";
import { Switch, Route } from "react-router-dom";

import AllProducts from "./allProducts";
import HistoryProducts from "./HistoryProducts";
import SavedProducts from "./SavedProducts";
import { MODULES } from "../../utils/routesNames";
import { NavLink } from "react-router-dom";
import ProductDetails from "./productDetails";

const ProductsNavbar: React.FC = () => {
  return (
    <div>
      <div className="product-navbar">
        <ul className="container-xl">
          <li>
            <NavLink
              to={MODULES.allProducts.url}
              className="product-nav-item"
              title="All Products">
              All Products
            </NavLink>
          </li>
          <li>
            <NavLink
              to={MODULES.savedProducts.url}
              className="product-nav-item"
              title="Saved Products">
              Saved Products
            </NavLink>
          </li>
          <li>
            <NavLink
              to={MODULES.historyProducts.url}
              className="product-nav-item"
              title="History">
              History
            </NavLink>
          </li>
        </ul>
      </div>

      <div>
        <Switch>
          <Route exact path={MODULES.allProducts.url} component={AllProducts} />
          <Route
            exact
            path={`${MODULES.allProducts.url}${MODULES.productDetails.url}/:productID`}
            component={ProductDetails}
          />
          <Route
            exact
            path={`${MODULES.allProducts.url}/:gridType/:id`}
            component={AllProducts}
          />
          <Route
            exact
            path={MODULES.savedProducts.url}
            component={SavedProducts}
          />
          <Route
            exact
            path={MODULES.historyProducts.url}
            component={HistoryProducts}
          />
        </Switch>
      </div>
    </div>
  );
};

export default ProductsNavbar;
