import React from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import ScrollAnimation from "react-animate-on-scroll";
import {
  PRIMO,
  NESTLE,
  KRAFT_FOODS,
  SHARWOODS,
  MAGGI,
  GOLDEN_CIRCLE,
  RIVIANA,
  TIP_TOP,
  INGHAMS_NEW,
  SANREMO_NEW,
  DIROSSI_NEW,
  BLACK_SWAN,
  CASA_DELLA_PASTA,
  CHEFMASTER,
} from "../../assets/images/images";

export default function SupplierList() {
  return (
    <div>
      <div className="wrapper">
        <Navbar />
        <section className="content-block about-block no-bg">
          <div className="container-xl text-center">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOnce={true}
              delay={100}
            >
              <h2 className="content-heading">Our Suppliers</h2>
            </ScrollAnimation>

              <div className="item-container mt-4">
                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={200}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Primo"
                    >
                      <img src={PRIMO} alt="Primo" />
                    </a>
                  </ScrollAnimation>
                </div>

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={300}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Nestle"
                    >
                      <img src={NESTLE} alt="Nestle" />
                    </a>
                  </ScrollAnimation>
                </div>

                {/* <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={600}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Allied Mills"
                    >
                      <img src={ALLIED_MILLS_NEW} alt="Allied Mills" />
                    </a>
                  </ScrollAnimation>
                </div> */}

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={400}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Kraft Foods"
                    >
                      <img src={KRAFT_FOODS} alt="Kraft Foods" />
                    </a>
                  </ScrollAnimation>
                </div>

                {/* <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={600}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Heinz"
                    >
                      <img src={HEINZ_SVG} alt="Heinz" />
                    </a>
                  </ScrollAnimation>
                </div> */}

                {/* <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={600}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Edgell"
                    >
                      <img src={EDGELL_NEW} alt="Edgell" />
                    </a>
                  </ScrollAnimation>
                </div> */}

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={500}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Sharwoods"
                    >
                      <img src={SHARWOODS} alt="Sharwoods" />
                    </a>
                  </ScrollAnimation>
                </div>

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={600}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Black Swan"
                    >
                      <img src={BLACK_SWAN} alt="Black Swan" />
                    </a>
                  </ScrollAnimation>
                </div>

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={700}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Inghams"
                    >
                      <img src={INGHAMS_NEW} alt="Inghams" />
                    </a>
                  </ScrollAnimation>
                </div>

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={800}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Casa Della Pasta"
                    >
                      <img src={CASA_DELLA_PASTA} alt="Casa Della Pasta" />
                    </a>
                  </ScrollAnimation>
                </div>

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={900}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Maggi"
                    >
                      <img src={MAGGI} alt="Maggi" />
                    </a>
                  </ScrollAnimation>
                </div>

                {/* <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={600}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Marathon"
                    >
                      <img src={MARATHON_NEW} alt="Marathon" />
                    </a>
                  </ScrollAnimation>
                </div> */}

                {/* <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={600}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Masterfood"
                    >
                      <img src={MASTERFOOD_NEW} alt="Masterfood" />
                    </a>
                  </ScrollAnimation>
                </div> */}

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1000}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Golden Circle"
                    >
                      <img src={GOLDEN_CIRCLE} alt="Golden Circle" />
                    </a>
                  </ScrollAnimation>
                </div>

                {/* <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={600}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Pura"
                    >
                      <img src={PURA} alt="Pura" />
                    </a>
                  </ScrollAnimation>
                </div> */}

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1100}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Riviana"
                    >
                      <img src={RIVIANA} alt="Riviana" />
                    </a>
                  </ScrollAnimation>
                </div>

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1200}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="San Remo"
                    >
                      <img src={SANREMO_NEW} alt="San Remo" />
                    </a>
                  </ScrollAnimation>
                </div>

                {/* <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={600}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Tatura"
                    >
                      <img src={TATURA} alt="Tatura" />
                    </a>
                  </ScrollAnimation>
                </div> */}

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1300}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Tip Top"
                    >
                      <img src={TIP_TOP} alt="Tip Top" />
                    </a>
                  </ScrollAnimation>
                </div>

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1400}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Di Rossi"
                    >
                      <img src={DIROSSI_NEW} alt="Di Rossi" />
                    </a>
                  </ScrollAnimation>
                </div>

                <div className="flex-item">
                  <ScrollAnimation
                    animateIn="bounceIn"
                    animateOnce={true}
                    delay={1400}
                  >
                    <a
                      href="/#"
                      onClick={(e) => e.preventDefault()}
                      className="supplier"
                      title="Chefmaster"
                    >
                      <img src={CHEFMASTER} alt="Chefmaster" />
                    </a>
                  </ScrollAnimation>
                </div>
              </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
