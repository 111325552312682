import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { MODULES } from "../utils/routesNames";

interface MyRouteProps extends RouteProps {
  component: any;
  rest?: any;
}

const PrivateRoute: React.SFC<MyRouteProps> = ({
  component: Component,
  ...rest
}) => (
  <>
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") ? ( // TODO: Valid Token, not expired or check user details
          <Component {...props} />
        ) : (
          <Redirect to={MODULES.login.url} />
        )
      }
    />
  </>
);

export default PrivateRoute;
