import React from "react";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import { MODULES } from "../../utils/routesNames";
import { PRICING_LIST_PDF } from "../../assets/files/file";

import ScrollAnimation from "react-animate-on-scroll";

export default function ProductPricing() {
  return (
    <div>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        {/* Start Product Pricing Block */}
        <section className="secondary-banner-block have-pannel">
          <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={400}>
            <p className="description">
              You can count on our reputation for honesty, quality, competitive
              pricing and unbeatable service. Please don’t hesitate to
              <Link
                to={MODULES.contactUs.url}
                className="accent-link"
                title="contact us"
              >
                {" "}
                contact us{" "}
              </Link>
              to discuss your requirements for food distribution in the
              Melbourne area.
            </p>
          </ScrollAnimation>
        </section>
        {/* End Product Pricing Block */}
      </ScrollAnimation>

      <ScrollAnimation animateOnce={true} animateIn="fadeInDown" delay={500}>
        {/* Start Product Pricing Pannel Block */}
        <div className="container-xl">
          <div className="pricing-pannel content-block">
            <div className="row">
              <div className="col-xl">
                <div className="pricing">
                  <h2 className="content-heading">
                    Products &amp; Pricing List
                  </h2>
                  <span>
                    Extensive research is carried out before we accept a product
                    as one of our stock items.
                  </span>
                </div>
              </div>
              <div className="col-xl-auto d-flex align-items-center justify-xl-content-end text-right">
                <Button
                  as={Link}
                  to={PRICING_LIST_PDF}
                  target="_blank"
                  download
                  variant="primary"
                  title="Download price list"
                >
                  Download price list
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* End Product Pricing Pannel Block */}
      </ScrollAnimation>
    </div>
  );
}
