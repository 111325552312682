import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import ProductsList from "../ProductGrid/productsList";
import SearchIcon from "../../assets/images/Search_Icon.png";
import { ProductDetails } from "../../modals/Product";
import { SearchAllProducts } from "../../services/services";
import { generalMessages as MSG } from "../../utils/constants";
import { MODULES } from "../../utils/routesNames";
import CategoryGrid from "../CategoryGrid/index";
// import SubCategory from '../SubCategoryGrid';
import ProductGrid from "../ProductGrid";
import { debounce } from "lodash";

export default function AllProducts() {
    const [products, setProducts] = useState<ProductDetails[]>([]);
    const [status, setStatus] = useState<string | null>(null);
    const [isSearched, setIsSearched] = useState<Boolean>(false);

    const handleFetchData = async (payload) => {
        setIsSearched(Boolean(payload.product_name));
        setProducts([]);
        setStatus(null);
        const response = await SearchAllProducts(payload);

        if (response.data.flag) {
            if (
                response.data.data.length !== 0 &&
                response.data.data.products.length !== 0
            ) {
                setProducts(response.data.data.products);
            } else {
                setStatus(MSG.EMPTY_PRODUCTS);
            }
        } else {
            setStatus(MSG.ERROR_MSG);
        }
    };

    const handleSearch = (e) => {
        if (e.target.value.length >= 3) {
            const payload = {
                product_name: e.target.value,
            };
            const apiCall = debounce(handleFetchData, 500);
            apiCall(payload);
        } else setIsSearched(false);
    };

    return (
        <div>
            <div className="product-wrapper container-xl">
                <div
                    className="form-group"
                    style={{
                        position: "relative",
                        width: "-webkit-fill-available",
                    }}
                >
                    <input
                        style={{
                            width: "-webkit-fill-available",
                            marginBottom: "20px",
                        }}
                        placeholder={"Search All Products"}
                        className="form-control"
                        onChange={handleSearch}
                    />

                    <img
                        src={SearchIcon}
                        alt="Search"
                        style={{
                            position: "absolute",
                            right: 18,
                            bottom: 10,
                            height: 30,
                        }}
                    />
                </div>
                {!isSearched ? (
                    <Switch>
                        <Route
                            exact
                            path={MODULES.allProducts.url}
                            component={CategoryGrid}
                        />
                        <Route
                            exact
                            path={`${MODULES.allProducts.url}/:gridType/:id`}
                            component={ProductGrid}
                        />
                    </Switch>
                ) : (
                    <div className="container-xl">
                        <ProductsList status={status} products={products} />
                    </div>
                )}
            </div>
        </div>
    );
}
