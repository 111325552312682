import React from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import ContactForm from "./contactForm";
import {
  LOCATION_PRIMARY,
  CONTACT_PRIMARY,
} from "../../assets/images/images";
import { NEWSLETTER_PRIMARY } from "../../assets/images/svgImage";
import { GOOGLE_MAP } from "../../utils/constants";

export default function ContactUs() {
  window.scroll({ top: 0, left: 0, behavior: "smooth" });
  return (
    <div>
      <div className="wrapper">
        <div className="sticky-header">
          <Navbar />
        </div>
        <section className="page-block contact-page-block">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-6">
                <ContactForm />
              </div>
              <div className="col-lg-6">
                <iframe
                  className="bordered-map"
                  title="Location of Shop"
                  src={GOOGLE_MAP}
                ></iframe>
              </div>
            </div>

            <div className="row contact-card-wrapper">
              <div className="col-lg-4">
                <div className="contact-card">
                  <h6 className="card-heading">location</h6>
                  <address>
                    12 Attercliffe Avenue, Pascoe Vale Melbourne, Victoria 3044
                  </address>
                  <img src={LOCATION_PRIMARY} alt="location" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contact-card">
                  <h6 className="card-heading">Conatct</h6>
                  <p>
                    <a href="tel:03 9355 8099">03 9355 8099</a>
                  </p>
                  <p>
                    <a href="tel:0432 145 925" className="mr-1">
                      0432 145 925
                    </a>
                    (Sales)
                  </p>
                  <img src={CONTACT_PRIMARY} alt="contact" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="contact-card">
                  <h6 className="card-heading">Email</h6>
                  <p>
                    <a href="mailto:enquiry@arzfoodservice.com.au">
                      enquiry@arzfoodservice.com.au
                    </a>
                  </p>
                  <img src={NEWSLETTER_PRIMARY} alt="news-letter" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
