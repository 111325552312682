import React from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";

export default function TermsConditions() {
  window.scroll({ top: 0, left: 0, behavior: "smooth" });
  return (
    <div>
      <div className="wrapper">
        <div className="sticky-header">
          <Navbar />
        </div>
        {/* Start About Block */}
        <section className="content-block about-block no-bg pt-sm-5">
          <div className="container-xl text-justify">
            <h2 className="content-heading text-center pb-sm-5 pb-4">Terms & Condition</h2>
            <ol className="ordered-list">
              {/* -- # 01 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Definitions</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    “Company” means ARZ Smallgoods Pty Ltd ATF Fakhri Family
                    Trust T/A ARZ Food Services, its successors and assigns or
                    any person acting on behalf of and with the authority of ARZ
                    Smallgoods Pty Ltd ATF Fakhri Family Trust.
                  </li>
                  <li className="ordered-list-item">
                    “Customer” means the person/s and if more than one jointly
                    and severally, buying the Goods as specified in any invoice,
                    document or order, and includes any person being a director
                    or shareholder of the Customer who has signed these terms
                    and conditions for and on its behalf.
                  </li>
                  <li className="ordered-list-item">
                    “Goods” means all Goods or Services supplied by the Company
                    to the Customer at the Customer’s request from time to time
                    (where the context so permits the terms ‘Goods’ or
                    ‘Services’ shall be interchangeable for the other).
                  </li>
                  <li className="ordered-list-item">
                    “Price” means the Price payable for the Goods as agreed
                    between the Company and the Customer in accordance with
                    clause 4 below.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 02 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Acceptance</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    The Customer is taken to have exclusively accepted and is
                    immediately bound, jointly and severally, by these terms and
                    conditions if the Customer places an order for or accepts
                    delivery of the Goods.
                  </li>
                  <li className="ordered-list-item">
                    These terms and conditions may only be amended with the
                    Company’s consent in writing and shall prevail to the extent
                    of any inconsistency with any other document or agreement
                    between the Customer and the Company.
                  </li>
                  <li className="ordered-list-item">
                    None of the Company’s agents or representatives are
                    authorised to make any representations, statements,
                    conditions or agreements not expressed by the manager of the
                    Company in writing nor is the Company bound by any such
                    unauthorised statements.
                  </li>
                  <li className="ordered-list-item">
                    The Customer acknowledges and accepts that the supply of
                    Goods for accepted orders may be subject to availability and
                    if, for any reason, Goods are not or cease to be available,
                    the Company reserves the right to vary the Price with
                    alternative Goods as per clause 4.2.  The Company also
                    reserves the right to halt all Services until such time as
                    the Company and the Customer agree to such changes.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 03 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Change in Control</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    The Customer shall give the Company not less than fourteen
                    (14) days prior written notice of any proposed change of
                    ownership of the Customer and/or any other change in the
                    Customer’s details (including but not limited to, changes in
                    the Customer’s name, address, contact phone or fax number/s,
                    or business practice). The Customer shall be liable for any
                    loss incurred by the Company as a result of the Customer’s
                    failure to comply with this clause.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 04 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Price and Payment</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    At the Company’s sole discretion the Price shall be either:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        as indicated on any invoice provided by the Company to
                        the Customer; or
                      </li>
                      <li className="ordered-list-item">
                        the Price as at the date of delivery of the Goods
                        according to the Company’s current price list; or
                      </li>
                      <li className="ordered-list-item">
                        the Company’s quoted price (subject to clause 4.2) which
                        will be valid for the period stated in the quotation or
                        otherwise for a period of fourteen (14) days.
                      </li>
                    </ol>
                  </li>
                  <li className="ordered-list-item">
                    The Company reserves the right to change the Price if a
                    variation to the Company’s quotation is requested. Any
                    variation from the plan of scheduled Services or
                    specifications of the Goods (including, but not limited to,
                    any variation as a result of stock availability,
                    fluctuations in currency exchange rates or increases to the
                    Seller in the cost of taxes, levies, materials and labour)
                    will be charged for on the basis of the Company’s quotation
                    and will be shown as variations on the invoice.
                  </li>
                  <li className="ordered-list-item">
                    At the Company’s sole discretion a non-refundable deposit
                    may be required.
                  </li>
                  <li className="ordered-list-item">
                    Time for payment for the Goods being of the essence, the
                    Price will be payable by the Customer on the date/s
                    determined by the Company, which may be:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        on delivery of the Goods;
                      </li>
                      <li className="ordered-list-item">
                        twenty-one (21) days following the end of the month in
                        which a statement is posted to the Customer’s address or
                        address for notices;
                      </li>
                      <li className="ordered-list-item">
                        the date specified on any invoice or other form as being
                        the date for payment; or
                      </li>
                      <li className="ordered-list-item">
                        failing any notice to the contrary, the date which is
                        thirty (30) days following the date of any invoice given
                        to the Customer by the Company.
                      </li>
                    </ol>
                  </li>
                  <li className="ordered-list-item">
                    Payment may be made by cash, cheque, bank cheque,
                    electronic/on-line banking, or by any other method as agreed
                    to between the Customer and the Company.
                  </li>
                  <li className="ordered-list-item">
                    Unless otherwise stated the Price does not include GST. In
                    addition to the Price the Customer must pay to the Company
                    an amount equal to any GST the Company must pay for any
                    supply by the Company under this or any other agreement for
                    the sale of the Goods. The Customer must pay GST, without
                    deduction or set off of any other amounts, at the same time
                    and on the same basis as the Customer pays the Price. In
                    addition the Customer must pay any other taxes and duties
                    that may be applicable in addition to the Price except where
                    they are expressly included in the Price.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 05 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Delivery of Goods</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    Delivery (“
                    <span className="font-weight-bold">Delivery</span>”) of the
                    Goods is taken to occur at the time that the Company (or the
                    Company’s nominated carrier) delivers the Goods to the
                    Customer’s nominated address even if the Customer is not
                    present at the address.
                  </li>
                  <li className="ordered-list-item">
                    At the Company’s sole discretion the cost of delivery is
                    either included in the Price or is in addition to the Price.
                  </li>
                  <li className="ordered-list-item">
                    The Customer must take delivery by receipt or collection of
                    the Goods whenever they are tendered for delivery. In the
                    event that the Customer is unable to take delivery of the
                    Goods as arranged then the Company shall be entitled to
                    charge a reasonable fee for redelivery and/or storage.
                  </li>
                  <li className="ordered-list-item">
                    Any time or date given by the Company to the Customer is an
                    estimate only. The Customer must still accept delivery of
                    the Goods even if late and the Company will not be liable
                    for any loss or damage incurred by the Customer as a result
                    of the delivery being late.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 06 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Risk</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    Risk of damage to or loss of the Goods passes to the
                    Customer on Delivery and the Customer must insure the Goods
                    on or before Delivery.
                  </li>
                  <li className="ordered-list-item">
                    If any of the Goods are damaged or destroyed following
                    delivery but prior to ownership passing to the Customer, the
                    Company is entitled to receive all insurance proceeds
                    payable for the Goods. The production of these terms and
                    conditions by the Company is sufficient evidence of the
                    Company’s rights to receive the insurance proceeds without
                    the need for any person dealing with the Company to make
                    further enquiries.
                  </li>
                  <li className="ordered-list-item">
                    If the Customer requests the Company to leave Goods outside
                    the Company’s premises for collection or to deliver the
                    Goods to an unattended location then such Goods shall be
                    left at the Customer’s sole risk.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 07 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Title</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    The Company and the Customer agree that ownership of the
                    Goods shall not pass until:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        the Customer has paid the Company all amounts owing to
                        the Company; and
                      </li>
                      <li className="ordered-list-item">
                        the Customer has met all of its other obligations to the
                        Company.
                      </li>
                    </ol>
                  </li>
                  <li className="ordered-list-item">
                    Receipt by the Company of any form of payment other than
                    cash shall not be deemed to be payment until that form of
                    payment has been honoured, cleared or recognised.
                  </li>
                  <li className="ordered-list-item">
                    It is further agreed that:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        until ownership of the Goods passes to the Customer in
                        accordance with clause 7.1 that the Customer is only a
                        bailee of the Goods and must return the Goods to the
                        Company on request.{" "}
                      </li>
                      <li className="ordered-list-item">
                        the Customer holds the benefit of the Customer’s
                        insurance of the Goods on trust for the Company and must
                        pay to the Company the proceeds of any insurance in the
                        event of the Goods being lost, damaged or destroyed.
                      </li>
                      <li className="ordered-list-item">
                        the Customer must not sell, dispose, or otherwise part
                        with possession of the Goods other than in the ordinary
                        course of business and for market value. If the Customer
                        sells, disposes or parts with possession of the Goods
                        then the Customer must hold the proceeds of any such act
                        on trust for the Company and must pay or deliver the
                        proceeds to the Company on demand.
                      </li>
                      <li className="ordered-list-item">
                        the Customer should not convert or process the Goods or
                        intermix them with other goods but if the Customer does
                        so then the Customer holds the resulting product on
                        trust for the benefit of the Company and must sell,
                        dispose of or return the resulting product to the
                        Company as it so directs.{" "}
                      </li>
                      <li className="ordered-list-item">
                        the Customer irrevocably authorises the Company to enter
                        any premises where the Company believes the Goods are
                        kept and recover possession of the Goods.
                      </li>
                      <li className="ordered-list-item">
                        the Company may recover possession of any Goods in
                        transit whether or not delivery has occurred.{" "}
                      </li>
                      <li className="ordered-list-item">
                        the Customer shall not charge or grant an encumbrance
                        over the Goods nor grant nor otherwise give away any
                        interest in the Goods while they remain the property of
                        the Company.
                      </li>
                      <li className="ordered-list-item">
                        the Company may commence proceedings to recover the
                        Price of the Goods sold notwithstanding that ownership
                        of the Goods has not passed to the Customer.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 08 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">
                  Personal Property Securities Act 2009 (“PPSA”)
                </h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    In this clause financing statement, financing change
                    statement, security agreement, and security interest has the
                    meaning given to it by the PPSA.
                  </li>
                  <li className="ordered-list-item">
                    Upon assenting to these terms and conditions in writing the
                    Customer acknowledges and agrees that these terms and
                    conditions constitute a security agreement for the purposes
                    of the PPSA and creates a security interest in all Goods
                    that have previously been supplied and that will be supplied
                    in the future by the Company to the Customer.
                  </li>
                  <li className="ordered-list-item">
                    The Customer undertakes to:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        promptly sign any further documents and/or provide any
                        further information (such information to be complete,
                        accurate and up-to-date in all respects) which the
                        Company may reasonably require to;
                        <ol className="ordered-list">
                          <li className="ordered-list-item">
                            register a financing statement or financing change
                            statement in relation to a security interest on the
                            Personal Property Securities Register;{" "}
                          </li>
                          <li className="ordered-list-item">
                            register any other document required to be
                            registered by the PPSA; or
                          </li>
                          <li className="ordered-list-item">
                            correct a defect in a statement referred to in
                            clause 8.3.1.1 or 8.3.1.2;
                          </li>
                        </ol>
                      </li>
                      <li className="ordered-list-item">
                        indemnify, and upon demand reimburse, the Company for
                        all expenses incurred in registering a financing
                        statement or financing change statement on the Personal
                        Property Securities Register established by the PPSA or
                        releasing any Goods charged thereby;
                      </li>
                      <li className="ordered-list-item">
                        not register a financing change statement in respect of
                        a security interest without the prior written consent of
                        the Company;
                      </li>
                      <li className="ordered-list-item">
                        not register, or permit to be registered, a financing
                        statement or a financing change statement in relation to
                        the Goods in favour of a third party without the prior
                        written consent of the Company;
                      </li>
                      <li className="ordered-list-item">
                        immediately advise the Company of any material change in
                        its business practices of selling the Goods which would
                        result in a change in the nature of proceeds derived
                        from such sales.
                      </li>
                    </ol>
                  </li>
                  <li className="ordered-list-item">
                    The Company and the Customer agree that sections 96, 115 and
                    125 of the PPSA do not apply to the security agreement
                    created by these terms and conditions.
                  </li>
                  <li className="ordered-list-item">
                    The Customer waives their rights to receive notices under
                    sections 95, 118, 121(4), 130, 132(3)(d) and 132(4) of the
                    PPSA.
                  </li>
                  <li className="ordered-list-item">
                    The Customer waives their rights as a grantor and/or a
                    debtor under sections 142 and 143 of the PPSA.
                  </li>
                  <li className="ordered-list-item">
                    Unless otherwise agreed to in writing by the Company, the
                    Customer waives their right to receive a verification
                    statement in accordance with section 157 of the PPSA.
                  </li>
                  <li className="ordered-list-item">
                    The Customer must unconditionally ratify any actions taken
                    by the Company under clauses 8.3 to 8.5.
                  </li>
                  <li className="ordered-list-item">
                    Subject to any express provisions to the contrary nothing in
                    these terms and conditions is intended to have the effect of
                    contracting out of any of the provisions of the PPSA.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 09 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Security and Charge</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    As security for any amounts due to the Company from time to
                    time, the Customer charges all of its legal and equitable
                    interest (both present and future) of whatsoever nature held
                    in any and all Real Property to the Company.
                  </li>
                  <li className="ordered-list-item">
                    Without limiting the generality of the charge in clause 9.1
                    the Customer, agrees, on request by the Company, to execute
                    any documents and do all things reasonably required by the
                    Company, to perfect the charge given in clause 9.1 including
                    registering a mortgage security over any real Property. The
                    Customer irrevocably appoints jointly and severally the
                    Company and each director of the Company as the Customer’s
                    true and lawful attorney/s for the purpose of executing and
                    registering such documents and taking all such steps in that
                    regard. The Customer indemnifies the Company and its
                    directors on and indemnity basis against all costs and
                    expenses incurred by the Company in connection with the
                    preparation and registration of any such steps needed to
                    perfect the security or prepare or register the mortgage
                    documents.
                  </li>
                  <li className="ordered-list-item">
                    The Customer consents unconditionally to the Company lodging
                    a caveat or caveats noting its interest in any real
                    Property.
                  </li>
                  <li className="ordered-list-item">
                    A statement in writing signed by an authorised officer of
                    the Company setting out the moneys due or owing to the
                    Company at the date of the statement shall be sufficient
                    evidence of the amount so due or owing until the contrary is
                    proven.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 10 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Customer’s Disclaimer</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    The Customer hereby disclaims any right to rescind, or
                    cancel the contract or to sue for damages or to claim
                    restitution arising out of any inadvertent misrepresentation
                    made to him by any servant or agent of the Company and the
                    Customer acknowledges that he buys the Goods relying solely
                    upon his own skill and judgement and that the Company shall
                    not be bound by nor responsible for any term, condition,
                    representation or warranty other than the warranty given by
                    the Manufacturer which warranty shall be personal to the
                    Customer and shall not be transferable to any subsequent
                    Customer.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 11 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">
                  Defects, Warranties and Returns, Competition and Consumer Act
                  2010 (CCA)
                </h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    The Customer must inspect the Goods on delivery and must
                    within twenty-four (24) hours of delivery notify the Company
                    in writing of any evident defect/damage, shortage in
                    quantity, or failure to comply with the description or
                    quote. The Customer must notify any other alleged defect in
                    the Goods as soon as reasonably possible after any such
                    defect becomes evident. Upon such notification the Customer
                    must allow the Company to inspect the Goods.
                  </li>
                  <li className="ordered-list-item">
                    Under applicable State, Territory and Commonwealth Law
                    (including, without limitation the CCA), certain statutory
                    implied guarantees and warranties (including, without
                    limitation the statutory guarantees under the CCA) may be
                    implied into these terms and conditions (
                    <span className="font-weight-bold">
                      Non-Excluded Guarantees
                    </span>
                    ).
                  </li>
                  <li className="ordered-list-item">
                    The Company acknowledges that nothing in these terms and
                    conditions purports to modify or exclude the Non-Excluded
                    Guarantees.
                  </li>
                  <li className="ordered-list-item">
                    Except as expressly set out in these terms and conditions or
                    in respect of the Non-Excluded Guarantees, the Company makes
                    no warranties or other representations under these terms and
                    conditions including but not limited to the quality or
                    suitability of the Goods. The Company’s liability in respect
                    of these warranties is limited to the fullest extent
                    permitted by law.
                  </li>
                  <li className="ordered-list-item">
                    If the Customer is a consumer within the meaning of the CCA,
                    the Company’s liability is limited to the extent permitted
                    by section 64A of Schedule 2.
                  </li>
                  <li className="ordered-list-item">
                    If the Company is required to replace the Goods under this
                    clause or the CCA, but is unable to do so, the Company may
                    refund any money the Customer has paid for the Goods.
                  </li>
                  <li className="ordered-list-item">
                    If the Customer is not a consumer within the meaning of the
                    CCA, the Company’s liability for any defect or damage in the
                    Goods is:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        limited to the value of any express warranty or warranty
                        card provided to the Customer by the Company at the
                        Company’s sole discretion;
                      </li>
                      <li className="ordered-list-item">
                        limited to any warranty to which the Company is
                        entitled, if the Company did not manufacture the Goods;
                      </li>
                      <li className="ordered-list-item">
                        otherwise negated absolutely.{" "}
                      </li>
                    </ol>
                  </li>
                  <li className="ordered-list-item">
                    Subject to this clause 11, returns will only be accepted
                    provided that:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        the Customer has complied with the provisions of clause
                        11.1; and
                      </li>
                      <li className="ordered-list-item">
                        the Company has agreed that the Goods are defective; and
                      </li>
                      <li className="ordered-list-item">
                        the Goods are returned within two (2) days of delivery
                        at the Customer’s cost (if that cost is not
                        significant); and
                      </li>
                      <li className="ordered-list-item">
                        the Goods are returned in as close a condition to that
                        in which they were delivered as is possible.
                      </li>
                    </ol>
                  </li>
                  <li className="ordered-list-item">
                    Notwithstanding clauses 11.1 to 11.8 but subject to the CCA,
                    the Company shall not be liable for any defect or damage
                    which may be caused or partly caused by or arise as a result
                    of:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        the Customer failing to properly maintain or store any
                        Goods;{" "}
                      </li>
                      <li className="ordered-list-item">
                        the Customer using the Goods for any purpose other than
                        that for which they were designed;{" "}
                      </li>
                      <li className="ordered-list-item">
                        the Customer continuing the use of any Goods after any
                        defect became apparent or should have become apparent to
                        a reasonably prudent operator or user;{" "}
                      </li>
                      <li className="ordered-list-item">
                        the Customer failing to follow any instructions or
                        guidelines provided by the Company;
                      </li>
                      <li className="ordered-list-item">
                        fair wear and tear, any accident, or act of God.
                      </li>
                    </ol>
                  </li>
                  <li className="ordered-list-item">
                    In the case of second hand Goods, unless the Customer is a
                    consumer under the CCA, the Customer acknowledges that it
                    has had full opportunity to inspect the second hand Goods
                    prior to delivery and accepts them with all faults and that
                    to the extent permitted by law no warranty is given by the
                    Company as to the quality or suitability for any purpose and
                    any implied warranty, statutory or otherwise, is expressly
                    excluded. The Customer acknowledges and agrees that the
                    Company has agreed to provide the Customer with the second
                    hand Goods and calculated the Price of the second hand Goods
                    in reliance of this clause 11.10.
                  </li>
                  <li className="ordered-list-item">
                    The Company may in its absolute discretion accept
                    non-defective Goods for return in which case the Company may
                    require the Customer to pay handling fees of up to ten
                    percent (10%) of the value of the returned Goods plus any
                    freight costs.
                  </li>
                  <li className="ordered-list-item">
                    Notwithstanding anything contained in this clause if the
                    Company is required by a law to accept a return then the
                    Company will only accept a return on the conditions imposed
                    by that law.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 12 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">
                  Default and Consequences of Default
                </h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    Interest on overdue invoices shall accrue daily from the
                    date when payment becomes due, until the date of payment, at
                    a rate of two and a half percent (2.5%) per calendar month
                    (and at the Company’s sole discretion such interest shall
                    compound monthly at such a rate) after as well as before any
                    judgment.
                  </li>
                  <li className="ordered-list-item">
                    If the Customer owes the Company any money the Customer
                    shall indemnify the Company from and against all costs and
                    disbursements incurred by the Company in recovering the debt
                    (including but not limited to internal administration fees,
                    legal costs on a solicitor and own client basis, the
                    Company’s contract default fee, and bank dishonour fees).
                  </li>
                  <li className="ordered-list-item">
                    Without prejudice to any other remedies the Company may
                    have, if at any time the Customer is in breach of any
                    obligation (including those relating to payment) under these
                    terms and conditions the Company may suspend or terminate
                    the supply of Goods to the Customer. The Company will not be
                    liable to the Customer for any loss or damage the Customer
                    suffers because the Company has exercised its rights under
                    this clause.
                  </li>
                  <li className="ordered-list-item">
                    Without prejudice to the Company’s other remedies at law the
                    Company shall be entitled to cancel all or any part of any
                    order of the Customer which remains unfulfilled and all
                    amounts owing to the Company shall, whether or not due for
                    payment, become immediately payable if:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        any money payable to the Company becomes overdue, or in
                        the Company’s opinion the Customer will be unable to
                        make a payment when it falls due;{" "}
                      </li>
                      <li className="ordered-list-item">
                        the Customer becomes insolvent, convenes a meeting with
                        its creditors or proposes or enters into an arrangement
                        with creditors, or makes an assignment for the benefit
                        of its creditors; or
                      </li>
                      <li className="ordered-list-item">
                        a receiver, manager, liquidator (provisional or
                        otherwise) or similar person is appointed in respect of
                        the Customer or any asset of the Customer.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 13 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Cancellation</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    The Company may cancel any contract to which these terms and
                    conditions apply or cancel delivery of Goods at any time
                    before the Goods are delivered by giving written notice to
                    the Customer. On giving such notice the Company shall repay
                    to the Customer any money paid by the Customer for the
                    Goods. The Company shall not be liable for any loss or
                    damage whatsoever arising from such cancellation.
                  </li>
                  <li className="ordered-list-item">
                    In the event that the Customer cancels delivery of Goods the
                    Customer shall be liable for any and all loss incurred
                    (whether direct or indirect) by the Company as a direct
                    result of the cancellation (including, but not limited to,
                    any loss of profits).
                  </li>
                  <li className="ordered-list-item">
                    Cancellation of orders for Goods made to the Customer’s
                    specifications, or for non-stocklist items, will definitely
                    not be accepted once production has commenced, or an order
                    has been placed.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 14 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Dispute Resolution </h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    If a dispute arises between the parties to this contract
                    then either party shall send to the other party a notice of
                    dispute in writing adequately identifying and providing
                    details of the dispute. Within fourteen (14) days after
                    service of a notice of dispute, the parties shall confer at
                    least once, to attempt to resolve the dispute. At any such
                    conference each party shall be represented by a person
                    having authority to agree to a resolution of the dispute. In
                    the event that the dispute cannot be so resolved either
                    party may by further notice in writing delivered by hand or
                    sent by certified mail to the other party refer such dispute
                    to arbitration. Any arbitration shall be:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        referred to a single arbitrator to be nominated by the
                        President of the Institute of Arbitrators Australia; and
                      </li>
                      <li className="ordered-list-item">
                        conducted in accordance with the Institute of
                        Arbitrators Australia Rules for the Conduct of
                        Commercial Arbitration.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 15 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Privacy Act 1988</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    The Customer agrees for the Company to obtain from a credit
                    reporting agency a credit report containing personal credit
                    information about the Customer in relation to credit
                    provided by the Company.
                  </li>
                  <li className="ordered-list-item">
                    The Customer agrees that the Company may exchange
                    information about the Customer with those credit providers
                    either named as trade referees by the Customer or named in a
                    consumer credit report issued by a credit reporting agency
                    for the following purposes:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        to assess an application by the Customer; and/or
                      </li>
                      <li className="ordered-list-item">
                        to notify other credit providers of a default by the
                        Customer; and/or
                      </li>
                      <li className="ordered-list-item">
                        to exchange information with other credit providers as
                        to the status of this credit account, where the Customer
                        is in default with other credit providers; and/or
                      </li>
                      <li className="ordered-list-item">
                        to assess the creditworthiness of the Customer.
                      </li>
                    </ol>
                    The Customer understands that the information exchanged can
                    include anything about the Customer’s creditworthiness,
                    credit standing, credit history or credit capacity that
                    credit providers are allowed to exchange under the Privacy
                    Act 1988.
                  </li>
                  <li className="ordered-list-item">
                    The Customer consents to the Company being given a consumer
                    credit report to collect overdue payment on commercial
                    credit (Section 18K(1)(h) Privacy Act 1988).
                  </li>
                  <li className="ordered-list-item">
                    The Customer agrees that personal credit information
                    provided may be used and retained by the Company for the
                    following purposes (and for other purposes as shall be
                    agreed between the Customer and Company or required by law
                    from time to time):
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        the provision of Goods; and/or
                      </li>
                      <li className="ordered-list-item">
                        the marketing of Goods by the Company, its agents or
                        distributors; and/or
                      </li>
                      <li className="ordered-list-item">
                        analysing, verifying and/or checking the Customer’s
                        credit, payment and/or status in relation to the
                        provision of Goods; and/or
                      </li>
                      <li className="ordered-list-item">
                        processing of any payment instructions, direct debit
                        facilities and/or credit facilities requested by the
                        Customer; and/or
                      </li>
                      <li className="ordered-list-item">
                        enabling the daily operation of Customer’s account
                        and/or the collection of amounts outstanding in the
                        Customer’s account in relation to the Goods.
                      </li>
                    </ol>
                  </li>
                  <li className="ordered-list-item">
                    The Company may give information about the Customer to a
                    credit reporting agency for the following purposes:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        to obtain a consumer credit report about the Customer;
                      </li>
                      <li className="ordered-list-item">
                        allow the credit reporting agency to create or maintain
                        a credit information file containing information about
                        the Customer.
                      </li>
                    </ol>
                  </li>
                  <li className="ordered-list-item">
                    The information given to the credit reporting agency may
                    include:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        personal particulars (the Customer’s name, sex, address,
                        previous addresses, date of birth, name of employer and
                        driver’s licence number);
                      </li>
                      <li className="ordered-list-item">
                        details concerning the Customer’s application for credit
                        or commercial credit and the amount requested;
                      </li>
                      <li className="ordered-list-item">
                        advice that the Company is a current credit provider to
                        the Customer;{" "}
                      </li>
                      <li className="ordered-list-item">
                        advice of any overdue accounts, loan repayments, and/or
                        any outstanding monies owing which are overdue by more
                        than sixty (60) days, and for which debt collection
                        action has been started;{" "}
                      </li>
                      <li className="ordered-list-item">
                        that the Customer’s overdue accounts, loan repayments
                        and/or any outstanding monies are no longer overdue in
                        respect of any default that has been listed;
                      </li>
                      <li className="ordered-list-item">
                        information that, in the opinion of the Company, the
                        Customer has committed a serious credit infringement
                        (that is, fraudulently or shown an intention not to
                        comply with the Customer’s credit obligations);
                      </li>
                      <li className="ordered-list-item">
                        advice that cheques drawn by the Customer for one
                        hundred dollars ($100) or more, have been dishonoured
                        more than once;
                      </li>
                      <li className="ordered-list-item">
                        that credit provided to the Customer by the Company has
                        been paid or otherwise discharged.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 16 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">Unpaid Seller’s Rights</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    Where the Customer has left any item with the Company for
                    repair, modification, exchange or for the Company to perform
                    any other service in relation to the item and the Company
                    has not received or been tendered the whole of any moneys
                    owing to it by the Customer, the Company shall have, until
                    all moneys owing to the Company are paid:
                    <ol className="ordered-list">
                      <li className="ordered-list-item">
                        a lien on the item; and
                      </li>
                      <li className="ordered-list-item">
                        the right to retain or sell the item, such sale to be
                        undertaken in accordance with any legislation applicable
                        to the sale or disposal of uncollected goods.
                      </li>
                    </ol>
                  </li>
                  <li className="ordered-list-item">
                    The lien of the Company shall continue despite the
                    commencement of proceedings, or judgment for any moneys
                    owing to the Company having been obtained against the
                    Customer.
                  </li>
                </ol>
              </li>
              <br />

              {/* -- # 17 -- */}
              <li className="ordered-list-item term-heading">
                <h5 className="font-weight-bold">General</h5>
                <ol className="ordered-list">
                  <li className="ordered-list-item">
                    The failure by the Company to enforce any provision of these
                    terms and conditions shall not be treated as a waiver of
                    that provision, nor shall it affect the Company’s right to
                    subsequently enforce that provision. If any provision of
                    these terms and conditions shall be invalid, void, illegal
                    or unenforceable the validity, existence, legality and
                    enforceability of the remaining provisions shall not be
                    affected, prejudiced or impaired.
                  </li>
                  <li className="ordered-list-item">
                    These terms and conditions and any contract to which they
                    apply shall be governed by the laws of the state of Victoria
                    in which the Company has its principal place of business,
                    and are subject to the jurisdiction of the courts in that
                    state.{" "}
                  </li>
                  <li className="ordered-list-item">
                    Subject to clause 11 the Company shall be under no liability
                    whatsoever to the Customer for any indirect and/or
                    consequential loss and/or expense (including loss of profit)
                    suffered by the Customer arising out of a breach by the
                    Company of these terms and conditions (alternatively the
                    Company’s liability shall be limited to damages which under
                    no circumstances shall exceed the Price of the Goods).
                  </li>
                  <li className="ordered-list-item">
                    The Customer shall not be entitled to set off against, or
                    deduct from the Price, any sums owed or claimed to be owed
                    to the Customer by the Company nor to withhold payment of
                    any invoice because part of that invoice is in dispute.
                  </li>
                  <li className="ordered-list-item">
                    The Company may license or sub-contract all or any part of
                    its rights and obligations without the Customer’s consent.
                  </li>
                  <li className="ordered-list-item">
                    The Customer agrees that the Company may amend these terms
                    and conditions at any time. If the Company makes a change to
                    these terms and conditions, then that change will take
                    effect from the date on which the Company notifies the
                    Customer of such change. The Customer will be taken to have
                    accepted such changes if the Customer makes a further
                    request for the Company to provide Goods to the Customer.{" "}
                  </li>
                  <li className="ordered-list-item">
                    Neither party shall be liable for any default due to any act
                    of God, war, terrorism, strike, lock-out, industrial action,
                    fire, flood, storm or other event beyond the reasonable
                    control of either party.
                  </li>
                  <li className="ordered-list-item">
                    The Customer warrants that it has the power to enter into
                    this agreement and has obtained all necessary authorisations
                    to allow it to do so, it is not insolvent and that this
                    agreement creates binding and valid legal obligations on it.{" "}
                  </li>
                </ol>
              </li>
              <br />
            </ol>
          </div>
        </section>
        {/* End About Block */}
      </div>
      <Footer />
    </div>
  );
}
