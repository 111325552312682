import React from "react";
import { Switch, Route } from "react-router-dom";
import { Router } from "react-router";
// utils
import { MODULES } from "../utils/routesNames";
import { history } from "../utils/appConfig";
// routes
import GuestRoute from "./GuestRoute";
import PrivateRoute from "./PrivateRoute";
// components
import OrderDetails from "../components/Order/orderDetails";
// containers
import Login from "../containers/Login";
import SignUp from "../containers/SignUp";
import AccountSettings from "../containers/AccountSettings";
import Products from "../containers/Products";
import OrderHistory from "../containers/Order";
import Cart from "../containers/Cart";
import Home from "../containers/Home";
import AboutUs from "../containers/AboutUs";
import ContactUs from "../containers/ContactUs";
import PrivacyPolicy from "../containers/PrivacyPolicy";
import TermsConditions from "../containers/TermsCondition";
import SupplierList from "../containers/SupplierList";
import ScrollToTop from "../components/ScrollToTop";

const Routes = () => {
  return (
    <div>
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <Route path={MODULES.home.url} component={Home} />
            <GuestRoute exact path={MODULES.login.url} component={Login} />
            <GuestRoute exact path={MODULES.signUp.url} component={SignUp} />
            <PrivateRoute
              exact
              path={MODULES.accountSettings.url}
              component={AccountSettings}
            />
            <Route exact path={`${MODULES.aboutUs.url}`} component={AboutUs} />
            <Route
              exact
              path={`${MODULES.contactUs.url}`}
              component={ContactUs}
            />
            <Route
              exact
              path={`${MODULES.privacyPolicy.url}`}
              component={PrivacyPolicy}
            />
            <Route
              exact
              path={`${MODULES.termsConditions.url}`}
              component={TermsConditions}
            />
            <Route
              exact
              path={`${MODULES.supplierList.url}`}
              component={SupplierList}
            />
            <PrivateRoute
              exact
              path={MODULES.orderHistory.url}
              component={OrderHistory}
            />
            <PrivateRoute exact path={MODULES.cart.url} component={Cart} />
            <PrivateRoute
              exact
              path={MODULES.savedProducts.url}
              component={Products}
            />
            <PrivateRoute
              exact
              path={MODULES.historyProducts.url}
              component={Products}
            />
            <Route
              exact
              path={`${MODULES.allProducts.url}/:gridType/:id`}
              component={Products}
            />
            <Route
              exact
              path={`${MODULES.allProducts.url}${MODULES.productDetails.url}/:productID`}
              component={Products}
            />
            <PrivateRoute
              exact
              path={`${MODULES.orderDetails.url}/:orderID`}
              component={OrderDetails}
            />
            <Route
              exact
              path={`${MODULES.products.url}/:filterType`}
              component={Products}
            />
            <Home />
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
};

export default Routes;
