import React, { useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/esm/Breadcrumb";
import { ProductDetails } from "../../modals/Product";
import {
    CategoryService,
    ProductListByCategory,
} from "../../services/services";
import { history } from "../../utils/appConfig";
import { findNestedObj, getPathTo } from "../../utils/common";
import { generalMessages as MSG } from "../../utils/constants";
import { MODULES } from "../../utils/routesNames";
import ProductsList from "./productsList";

const ProductGrid = ({
    match: {
        params: { id },
    },
}) => {
    const [products, setProducts] = useState<ProductDetails[]>([]);
    const [root, setRoot] = useState<any[]>([]);
    const [status, setStatus] = useState<string | null>(null);
    const [breadCrumbs, setBreadCrumbs] = useState([]);

    useEffect(() => {
        getCategories().then((res) => {
            setRoot(res);
            getProducts(res);
        });
    }, []); // eslint-disable-line

    const getCategories = async () => {
        const result = await CategoryService();

        if (result.data.flag) {
            if (result.data.data.length !== 0) {
                return result.data.data;
            }
        }
    };

    const getProducts = async (res: any) => {
        let path;
        const resu = await CategoryService();
        if (resu.data.flag) {
            const category = resu.data.data;
            if (category?.length !== 0) {
                const item = findNestedObj(category, "id", +id);
                path = getPathTo(item ? item.name : "", category);
            }
        }

        const result = await ProductListByCategory(id);
        if (result.data.flag) {
            setStatus(null);
            if (result.data.data.length !== 0) {
                setRoot(res);
                setProducts(result.data.data);
                setBreadCrumbs(path);
            } else {
                setStatus(MSG.EMPTY_PRODUCTS);
                setBreadCrumbs(path);
            }
        } else {
            setStatus(MSG.ERROR_MSG);
        }
    };

    const jumpToCategory = (name: string) => {
        const item = findNestedObj(root, "name", name);
        const path = getPathTo(item ? item.name : "", root);
        history.push(MODULES.allProducts.url, { item, path });
    };

    return (
        <div>
            <section className="product-details">
                <div className="container-xl">
                    <Breadcrumb className="primary-breadcrumb">
                        {breadCrumbs &&
                            breadCrumbs.map((item, index) => {
                                return index === breadCrumbs.length - 1 ? (
                                    <Breadcrumb.Item active key={index}>
                                        <span className="text-truncate">
                                            {item}
                                        </span>
                                    </Breadcrumb.Item>
                                ) : index === 0 ? (
                                    <Breadcrumb.Item
                                        key={index}
                                        onClick={() =>
                                            history.push(
                                                MODULES.allProducts.url
                                            )
                                        }
                                    >
                                        {item}
                                    </Breadcrumb.Item>
                                ) : (
                                    <Breadcrumb.Item
                                        key={index}
                                        onClick={() => jumpToCategory(item)}
                                    >
                                        {item}
                                    </Breadcrumb.Item>
                                );
                            })}
                    </Breadcrumb>
                    
                    <ProductsList status={status} products={products} />
                </div>
            </section>
        </div>
    );
};

export default ProductGrid;
