import React, { useState, useEffect, useContext } from "react";
import { HistoryProduct as Product } from "../../../modals/Product";
import { HistoryProductService } from "../../../services/services";
import { generalMessages } from "../../../utils/constants";
import Loader from "../../Loader";
import HistoryProductDetail from "./historyProductDetail";
import HistoryProduct from "./historyProduct";
import AppContext from "../../../context/AppContext";
import AddToCartForm from "../../AddToCartForm";
import SearchIcon from "../../../assets/images/Search_Icon.png";

const HistoryProducts = () => {
    const { tokenExpired } = useContext(AppContext);
    const [products, setProducts] = useState<Product[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
    const [status, setStatus] = useState<string | null>(null);
    //const [input, setInput] = useState("");
    const [filterproducts, setfilterProducts] = useState<Product[]>([]);

    useEffect(() => {
        getUserHistoryProducts();
    }, []); // eslint-disable-line

    const getUserHistoryProducts = async () => {
        setStatus(null);
        setProducts([]);
        HistoryProductService()
            .then((result) => {
                if (result.data.flag) {
                    setProducts(result.data.data);
                    setfilterProducts(result.data.data);
                    if (result.data.data.length === 0) {
                        setStatus(generalMessages.EMPTY_PRODUCTS);
                    }
                }
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    tokenExpired();
                }
                setStatus(generalMessages.ERROR_MSG);
            });
    };

    /////search features

    const handleSearch = (keyword) => {
        const filtered = products.filter((p1) => {
            return p1.product_name
                .toLowerCase()
                .includes(keyword.toLowerCase());
        });
        setfilterProducts(filtered);
    };

    return selectedProduct ? (
        <HistoryProductDetail
            id={selectedProduct}
            backToList={() => setSelectedProduct(null)}
        />
    ) : (
        <div className="product-wrapper container-xl">
            <div
                className="form-group"
                style={{
                    position: "relative",
                    width: "-webkit-fill-available",
                }}
            >
                <input
                    style={{
                        width: "-webkit-fill-available",
                        // background: "#f4f4f4",
                        marginBottom: "20px",
                    }}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder={"Search History Product"}
                    className="form-control"
                />

                <img
                    src={SearchIcon}
                    alt=""
                    style={{
                        position: "absolute",
                        right: 18,
                        bottom: 10,
                        height: 30,
                    }}
                />
            </div>
            {/* Loop Start */}
            <section className="product-details">
                <div className="container-xl">
                    {/* history product loop */}
                    <div className="product-list-item-wrapper">
                        {status
                            ? status
                            : filterproducts.length !== 0
                            ? filterproducts.map((product, index) => (
                                  <HistoryProduct
                                      key={index}
                                      {...product}
                                      loadItem={() =>
                                          setSelectedProduct(product.product_id)
                                      }
                                  />
                              ))
                            : generalMessages.EMPTY_PRODUCTS}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HistoryProducts;
