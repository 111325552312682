import React, { useState, useEffect } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { NO_IMG } from "../../../assets/images/images";
import { Product } from "../../../modals/Product";
import { isValidImageUrl } from "../../../utils/common";
import { productType } from "../../../utils/constants";
import { history } from "../../../utils/appConfig";
import { MODULES } from "../../../utils/routesNames";
//for add to cart
import ListingWidget from "../../AddToCartForm/ListingWidget";
import { ProductDetails } from "../../../modals/Product";
import { GetProductDetailsService } from "../../../services/services";
import { generalMessages as MSG } from "../../../utils/constants";

interface ParentProps {
  deleteItem: Function;
  moveItem: Function;
  loadItem: Function;
}

const SavedProduct: React.FC<Product & ParentProps> = ({
  type,
  product_image,
  product_name,
  product_price,
  deleteItem,
  moveItem,
  loadItem,
  product_id,
  out_of_stock,
}) => {
  const [image, setImage] = useState(product_image);
  const [product, setProduct] = useState<ProductDetails>();
  const [status, setStatus] = useState<string | null>(null);

  // useEffect(() => {
  //   getProjectDetails(product_id);
  // }, []); // eslint-disable-line

  const getProjectDetails = async (product_id: number) => {
    const result = await GetProductDetailsService(product_id.toString());
    if (result.data.flag && result.data.data.length !== 0) {
      setProduct(result.data.data);
    } else {
      setStatus(MSG.PRODUCT_NOT_FOUND);
    }
  };

  const handleSelect = (value) => {
    if (value === "move") {
      moveItem();
    } else {
      deleteItem();
    }
  };

  return (
    <div className="product-list-item">
      <i className="product-list-item-img">
        <img
          onError={() => setImage(NO_IMG)}
          src={isValidImageUrl(image) ? image : NO_IMG}
          alt={product_image}
        />
      </i>
      <div className="product-list-item-detail">
        <div>
          <div
            className="label-action"
            onClick={() => {
              loadItem();
              // history.push(
              //   `${MODULES.allProducts.url}${MODULES.productDetails.url}/${product_id}`
              // );
            }}
          >
            <span>
              {product_name}
              <DropdownButton
                onSelect={handleSelect}
                onClick={(e) => e.stopPropagation()}
                title=""
                className="default-menu btn-more"
              >
                <Dropdown.Item eventKey="move">
                  {type === productType.OCCASIONALLY
                    ? "Move to Commonly Ordered Product"
                    : "Move to Occasionally Ordered Product"}
                </Dropdown.Item>
                <Dropdown.Item eventKey="delete">Delete Item</Dropdown.Item>
              </DropdownButton>
            </span>
          </div>
          <span>$ {product_price}</span>
        </div>
        <Form.Group className="ml-md-auto form-group">
          <ListingWidget
            id={product_id}
            is_saved={0}
            isListing
            out_of_stock={out_of_stock}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export default SavedProduct;
