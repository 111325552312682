import React from "react";
import Navbar from "../../components/NavBar";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import {
  ABOUT_US_BANNER,
  HIW_DELIVERED
} from "../../assets/images/images";
import {
  HIW_MENU,
  HIW_LIST,
} from "../../assets/images/svgImage";

export default function AboutUs() {
  window.scroll({ top: 0, left: 0, behavior: "smooth" });
  return (
    <div>
      <div className="wrapper">
        <div className="sticky-header">
          <Navbar />
        </div>
        <section className="content-block about-block no-bg">
          <div className="container-xl text-center">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h2 className="content-heading">About ARZ Food Service</h2>
                <p>
                  ARZ is a family owned and operated business that has been
                  serving the Melbourne area for over 40 years. Our reputation
                  in the marketplace is defined as price fighters with an
                  exceptional grade of quality products.
                </p>
                <p>
                  ARZ are leaders in the food service industry. We are
                  well-established wholesale suppliers of food, grocery,
                  restaurant, and catering supplies. We provide distribution and
                  refrigerated transport for an extensive range of small goods,
                  dairy, dry, frozen, packaging and chemical products.
                </p>
                <img
                  className="my-3 img-fluid"
                  src={ABOUT_US_BANNER}
                  alt="about us banner"
                />
                <p className="px-3">
                  We are committed to providing quality products and reliable
                  service to our large and growing clientele. We have a large
                  distribution warehouse, carrying many main stream, high
                  quality and specialty products. Whether you are a small
                  business with occasional food supply orders or a large company
                  requiring long-term and regular delivery, we can accommodate
                  your needs with personal and friendly service. We have been
                  providing food distribution for over four decades and have
                  extensive food service expertise.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="content-block how-it-work text-center">
          <div className="container-xl">
            <h2 className="content-heading">How It Works</h2>
            {/* <p>
              ARZ is a family owned and operated business that has been serving
              the Melbourne area for over{" "}
            </p> */}
            <div className="row">
              <div className="col-lg-4">
                <div className="info-card">
                  <img src={HIW_MENU} alt="HIW menu" />
                  <p className="title">variety of products</p>
                  {/* <p>
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-card">
                  <img src={HIW_LIST} alt="HIW list" />
                  <p className="title">add items quickly</p>
                  {/* <p>
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting.
                  </p> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="info-card">
                  <img src={HIW_DELIVERED} alt="HIW delivered" />
                  <p className="title">get delivered</p>
                  {/* <p>
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="content-block">
          <div className="container-xl">
            <div className="explore-food-block">
              <h2 className="content-heading">Bringing joy of food</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry
              </p>
              <Button className="btn btn-white">Explore Now</Button>
            </div>
          </div>
        </section> */}

        <section className="about-us-banner"></section>
        {/* <section className="content-block service-block">
          <div className="container-xl text-center">
            <div className="main-content">
              <h2 className="content-heading">
                Why should I use ARZ Food Service ?
              </h2>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem
                Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident.
              </p>

              <p>
                Many desktop publishing packages and web page editors now use
                Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
                Various versions have evolved over the years, sometimes by
                accident, sometimes on purpose (injected humour and the like).
              </p>

              <p>
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using 'Content
                here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors The point of
                using Lorem Ipsum is that it has a more-or-less normal
                distribution of letters, as opposed to using 'Content here,
                content here', making it look like readable English. Many
                desktop publishing packages and web page editors now u
              </p>
            </div>
          </div>
        </section> */}
        <Contact />
      </div>

      <Footer />
    </div>
  );
}
