// export const productImageDomain =
//   "https://www.anasource.com/team4/arzfoodservice-admin/public/storage/uploads/";
export const productImageDomain =
  "https://arzfoodservice.com.au/arz_admin/public/storage/uploads/";

export const GOOGLE_MAP =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3155.603390069077!2d144.94548721505893!3d-37.72898633696953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65b39a02a2817%3A0xac9f6acead6cd176!2s12%20Attercliffe%20Ave%2C%20Pascoe%20Vale%20VIC%203044%2C%20Australia!5e0!3m2!1sen!2sin!4v1598946217742!5m2!1sen!2sin";

export const regEx = {
  mobileNumberValidation: /^\+?\d{10}$/,
  faxNumber: /^\+?[0-9]{7,}$/,
  imageUrl: /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)/,
};

export const mobileNumberMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const ORDER_STATUS = {
  0: {
    value: "Pending",
    color: "warning",
  },
  1: {
    value: "Shipped",
    color: "info",
  },
  2: {
    value: "Delivered",
    color: "success",
  },
  3: {
    value: "Cancelled",
    color: "secondary",
  },
  4: {
    value: "Returned",
    color: "danger",
  },
};

export enum productType {
  COMMONLY = "commonly",
  OCCASIONALLY = "occasionally",
}

export const savedProductTypes = [
  { value: 1, name: "Commonly Ordered Products", type: productType.COMMONLY },
  {
    value: 2,
    name: "Occasionally Ordered Products",
    type: productType.OCCASIONALLY,
  },
];

export const addressTypeOptions = [
  { value: "", label: "None" },
  { value: "Billing", label: "Billing" },
  { value: "Contract", label: "Contract" },
  { value: "Home", label: "Home" },
  { value: "Shipping", label: "Shipping" },
];

export const companyTypeOptions = [
  { value: "", label: "None" },
  { value: "Business", label: "Business" },
  { value: "Public", label: "Public" },
  { value: "Private", label: "Private" },
  { value: "Associate", label: "Associate" },
];

export enum productFilters {
  ASCENDING = "Ascending",
  DECESENDING = "Descending",
  NEWEST = "Newest",
  LOW_TO_HIGH = "low to high",
  HIGH_TO_LOW = "high to low",
}

export const contactUsMessages = {
  NAME_REQUIRED: "Name is required",
  EMAIL_REQUIRED: "Email is required",
  MESSAGE_REQUIRED: "Message is required",
  INVALID_EMAIL: "Email is invalid",
  MESSAGE_MAX_LENGTH: 500,
  LIMIT_EXCEEDED: "Message length exceeded",
  SUCCESS: "your inquiry has been submitted successfully.",
};

export const addToCartMessages = {
  MIN_QTY: 1,
  MAX_QTY: 50,
  QTY_REQUIRED: "Minimum required quantity is 1",
  INVALID_QTY: "Please enter valid quantity",
  QTY_EXCEEDED: "Quantity limit exceeded",
};

export const askQuestionMessages = {
  NAME_REQUIRED: "Name is required",
  MOBILE_REQUIRED: "Mobile number is required",
  EMAIL_REQUIRED: "Email is required",
  INVALID_EMAIL: "Email is invalid",
  INVALID_MOBILE: "Mobile number is not valid",
  COMPANY_REQUIRED: "Company name is required",
  COMMENT_REQUIERD: "Message is required",
};

export const checkoutMessages = {
  DATE_REQUIRED: "Delivery Date is required",
};

export const myAccountMessages = {
  FIRST_NAME_REQUIERD: "First name is required",
  LAST_NAME_REQUIERD: "Last name is required",
  EMAIL_REQUIRED: "Email is required",
  INVALID_EMAIL: "Email is invalid",
  MOBILE_REQUIRED: "Mobile number is required",
  INVALID_MOBILE: "Mobile number is not valid",
  PUBLIC_NOTE_REQUIRED: "Public note is required",
  ADDRESS_TYPE_REQUIRED: "Address type is required",
  TRADING_NAME_REQUIRED: "Trading name is required",
  COMPANY_TYPE_REQUIRED: "Company type is required",
  BUSINESS_NAME_REQUIRED: "Business name is required",
  TELEPHONE_REQUIRED: "Telephone number is required",
  INVALID_TELEPHONE: "Telephone number is invalid",
  FAX_REQUIRED: "FAX number is required",
  INVALID_FAX: "FAX number is invalid",
  ABN_REQUIRED: "ABN number is required",
};

export const generalMessages = {
  EMPTY_CATEGORIES: "No Categories Found :(",
  EMPTY_PRODUCTS: "No Products Found :(",
  PRODUCT_NOT_FOUND: "Couldn't find the product :(",
  ERROR_MSG: "Something is not right",
  LOGIN_SUCCESS: "Successfully logged in!",
  QUESTION_SUBMITTED: "Your Question has been submitted successfully",
  RE_LOGIN_REQUIRED: "Session expired ! Login again to continue",
  NO_ORDERS: "You haven't placed any orders yet.",
  REGISTER_SUCCESS:
    "Your account will be configured within 2 business days, once setup is complete, your username and password will be sent via SMS",
};

export const orderDetailMessages = {
  SUCCESS: "Successfully added to cart",
};

export const toastProps = {
  delay: 3000,
};
