import React from "react";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import { MODULES } from "../../utils/routesNames";
import ScrollAnimation from "react-animate-on-scroll";

export default function Product() {
  return (
    <div>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <section className="secondary-banner-block height-550">
          <ScrollAnimation animateOnce={true} animateIn="fadeInUp" delay={400}>
            <p className="description">
              We provide distribution and refrigerated transport for an
              extensive range of small goods, dairy, dry, frozen, packaging and
              chemical products.
            </p>
            <Button
              as={Link}
              to={MODULES.allProducts.url}
              className="btn btn-white"
              title="View All Products"
            >
              View All Products
            </Button>
          </ScrollAnimation>
        </section>
      </ScrollAnimation>
    </div>
  );
}
